import { useBlockNoteEditor } from '@blocknote/react'
import { useFileUpload } from '@gain/cms/api'
import Alert, { alertClasses } from '@mui/material/Alert'
import CircularProgress from '@mui/material/CircularProgress'
import { styled } from '@mui/material/styles'
import { useState } from 'react'
import { useDropzone } from 'react-dropzone'

import { ACCEPT_ALL_FILES } from '../../../../../common/input-fields/input-file'

export interface NodeEditorFileUploadProps {
  onFileUpload: (id: number, name: string) => void
}

const StyledRoot = styled('div', {
  shouldForwardProp: (prop) => !['isDragActive', 'enabled'].includes(prop.toString()),
})<{ isDragActive: boolean; enabled }>(({ isDragActive }) => ({
  [`& .${alertClasses.root}`]: {
    cursor: 'pointer',

    ...(isDragActive && {
      border: '1px solid black',
    }),
  },
}))

export const StyledAlert = styled(Alert)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  padding: 0,

  [`& .${alertClasses.message}`]: {
    padding: theme.spacing(1.25),
    ...theme.typography.caption,
  },

  [`& .${alertClasses.icon}`]: {
    margin: theme.spacing(0, 0, 0, 1),
  },

  [`& .${alertClasses.action}`]: {
    marginRight: theme.spacing(1),
  },
}))

export default function NodeEditorFileUpload({ onFileUpload }: NodeEditorFileUploadProps) {
  const [uploading, setUploading] = useState<boolean>(false)
  const editor = useBlockNoteEditor()
  const uploadFile = useFileUpload()

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: async (acceptedFiles) => {
      try {
        if (uploading) {
          return
        }

        setUploading(true)

        if (acceptedFiles.length === 1) {
          const fileId = await uploadFile(acceptedFiles[0], { secure: true })

          if (fileId) {
            onFileUpload(fileId, acceptedFiles[0].name || 'unknown')
          }
        }
      } finally {
        setUploading(false)
      }
    },
    noDrag: true,
    accept: ACCEPT_ALL_FILES,
    maxFiles: 1,
  })

  // Upload is only allowed if the editor is editable
  if (!editor.isEditable) {
    return null
  }

  return (
    <StyledRoot
      enabled={editor.isEditable}
      isDragActive={isDragActive}>
      <StyledAlert
        action={uploading && <CircularProgress size={16} />}
        icon={false}
        severity={'info'}
        variant={'standard'}
        {...getRootProps()}>
        <input {...getInputProps()} />
        Click here to upload
      </StyledAlert>
    </StyledRoot>
  )
}
