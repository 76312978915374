import '@blocknote/mantine/style.css'

import { filterSuggestionItems } from '@blocknote/core'
import { BlockNoteView } from '@blocknote/mantine'
import {
  BasicTextStyleButton,
  BlockTypeSelect,
  blockTypeSelectItems,
  CreateLinkButton,
  DefaultReactSuggestionItem,
  FormattingToolbar,
  FormattingToolbarController,
  getDefaultReactSlashMenuItems,
  SuggestionMenuController,
  useCreateBlockNote,
} from '@blocknote/react'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import { styled } from '@mui/material/styles'
import {
  ForwardedRef,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from 'react'

import { noteEditorFileSlashCommand } from './blocks/file'
import NoteEditorDropFileContainer from './note-editor-drop-file-container'
import { blockEditorSchema } from './note-editor-schema'
import {
  getEditorStateFromStorage,
  restoreEditorStateFromStorage,
  storeEditorStateInStorage,
} from './note-editor-utils'

export interface NoteEditorProps {
  initialContent?: object[]
  readonly?: boolean
  storageKey?: string
}

export const noteClasses = generateUtilityClasses('Note', ['root', 'view'])

const StyledNoteContainer = styled('div')(({ theme }) => ({
  height: '100%',

  [`& .${noteClasses.view}`]: {
    height: '100%',
  },

  '& .bn-editor': {
    paddingRight: theme.spacing(3),
  },

  '& .bn-block-content': {
    ...theme.typography.body2,
    fontSize: 15,
  },
}))

// List containing all default Slash Menu Items, as well as our custom one.
function getCustomSlashMenuItems(
  editor: typeof blockEditorSchema.BlockNoteEditor
): DefaultReactSuggestionItem[] {
  return [
    ...getDefaultReactSlashMenuItems(editor).filter((item) => {
      return ['Paragraph', 'Numbered List', 'Bullet List'].includes(item.title)
    }),
    noteEditorFileSlashCommand(editor),
  ]
}

function NoteEditor(
  { initialContent, readonly, storageKey }: NoteEditorProps,
  ref: ForwardedRef<typeof blockEditorSchema.BlockNoteEditor>
) {
  const editor = useCreateBlockNote({
    schema: blockEditorSchema,
    initialContent: initialContent || getEditorStateFromStorage(storageKey),
  })

  const previousStorageKey = useRef(storageKey)

  const handleGetSuggestionItems = useCallback(
    async (query: string) => {
      return filterSuggestionItems(getCustomSlashMenuItems(editor), query)
    },
    [editor]
  )

  const handleChange = useCallback(() => {
    if (editor && storageKey) {
      storeEditorStateInStorage(editor, storageKey)
    }
  }, [editor, storageKey])

  useImperativeHandle(ref, () => editor)

  useEffect(() => {
    // If the previous storage key is different from the current one, we store the current draft note in local storage
    // and try to restore the draft note, if any, of the new storage key
    if (editor && previousStorageKey.current && storageKey !== previousStorageKey.current) {
      // Store current blocks
      storeEditorStateInStorage(editor, previousStorageKey.current)
      // Restore existing blocks
      restoreEditorStateFromStorage(editor, storageKey)
      previousStorageKey.current = storageKey
    }
  }, [editor, storageKey])

  return (
    <StyledNoteContainer className={noteClasses.root}>
      <NoteEditorDropFileContainer
        editor={editor}
        enabled={!readonly}>
        <BlockNoteView
          className={noteClasses.view}
          editable={!readonly}
          editor={editor}
          formattingToolbar={false}
          onChange={handleChange}
          slashMenu={false}
          theme={'light'}>
          <SuggestionMenuController
            getItems={handleGetSuggestionItems}
            triggerCharacter={'/'}
          />

          <FormattingToolbarController
            formattingToolbar={() => (
              <FormattingToolbar>
                <BlockTypeSelect
                  key={'blockTypeSelect'}
                  items={blockTypeSelectItems(editor.dictionary).filter((item) =>
                    ['paragraph', 'bulletListItem', 'numberedListItem'].includes(item.type)
                  )}
                />

                <BasicTextStyleButton
                  key={'boldStyleButton'}
                  basicTextStyle={'bold'}
                />
                <BasicTextStyleButton
                  key={'italicStyleButton'}
                  basicTextStyle={'italic'}
                />
                <BasicTextStyleButton
                  key={'underlineStyleButton'}
                  basicTextStyle={'underline'}
                />
                <BasicTextStyleButton
                  key={'strikeStyleButton'}
                  basicTextStyle={'strike'}
                />
                <CreateLinkButton key={'createLinkButton'} />
              </FormattingToolbar>
            )}
          />
        </BlockNoteView>
      </NoteEditorDropFileContainer>
    </StyledNoteContainer>
  )
}

export default forwardRef(NoteEditor)
