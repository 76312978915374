import { useNotes } from '@gain/cms/api'
import { SlotName, SlotPortal } from '@gain/components/slot'
import { useOnMountEffect } from '@gain/utils/react'
import { useLocalStorage } from '@gain/utils/storage'
import MenuIcon from '@mui/icons-material/Menu'
import LoadingButton from '@mui/lab/LoadingButton'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import Drawer, { drawerClasses } from '@mui/material/Drawer'
import generateUtilityClasses from '@mui/material/generateUtilityClasses'
import IconButton from '@mui/material/IconButton'
import Stack from '@mui/material/Stack'
import { styled } from '@mui/material/styles'
import Typography from '@mui/material/Typography'
import { useCallback, useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router'

import { useInputFormAPI } from '../../common/input-fields'
import Note from './note'
import NoteEditor, {
  BlockNoteEditor,
  filterEditorBlocks,
  getEditorStateFromStorage,
  noteClasses,
} from './note-editor'
import { NoteResourceField, useNotesListFilter } from './notes-drawer-hooks'

export interface NotesDrawerProps {
  resourceId?: number
  resourceField: NoteResourceField
  routeTabs: string[]
}

export const NOTES_DRAWER_WIDTH = '35vw'

export const notesDrawerClasses = generateUtilityClasses('NotesDrawer', [
  'root',
  // We name it "drawerOpen" instead of "open", as Mui changes "open" to "Mui-open" which removes the "NotesDrawer-" prefix
  'drawerOpen',
])

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  flexShrink: 0,
  willChange: 'width',
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.shortest,
  }),

  [`& .${drawerClasses.paper}`]: {
    width: NOTES_DRAWER_WIDTH,
    maxWidth: NOTES_DRAWER_WIDTH,
    // 112 = 64 (bar) + 48 (tabs)
    height: 'calc(100% - 112px)',
    top: 112,
    zIndex: 1,
    borderLeft: 'none',
    boxShadow: '0px 2px 8px 0px rgba(0, 0, 0, 0.04)',
  },
}))

const StyledNewNoteContainer = styled(Stack)(({ theme }) => ({
  position: 'sticky',
  bottom: 0,
  background: theme.palette.background.default,
  borderTop: `1px solid ${theme.palette.divider}`,
  maxHeight: '60%',

  [`& .${noteClasses.root}`]: {
    overflow: 'scroll',
  },
}))

export default function NotesDrawer({ resourceField, resourceId, routeTabs }: NotesDrawerProps) {
  const [open, toggleOpenNotes] = useLocalStorage('notes-drawer', false)
  const [creatingAllNotes, setCreatingAllNotes] = useState(false)
  const editorRef = useRef<BlockNoteEditor>(null)
  const params = useParams<{ tab: string }>()

  const filter = useNotesListFilter(resourceField, resourceId)
  // Stay backward compatible
  const storageType = ['assetId', 'dealId'].includes(resourceField)
    ? resourceField.replace('Id', '')
    : resourceField

  const inputFormAPI = useInputFormAPI({
    createMethod: 'cms.createNote',
  })

  const swrNotes = useNotes(() => {
    if (!params.tab || !filter) {
      return null
    }

    return {
      filter,
      sort: ['-createdAt'],
      limit: 100,
      page: 0,
    }
  })

  const handleBodyCssClass = (newOpen: boolean) => {
    if (newOpen) {
      document.body.classList.add(notesDrawerClasses.drawerOpen)
    } else {
      document.body.classList.remove(notesDrawerClasses.drawerOpen)
    }
  }

  useEffect(() => {
    // If we already have data, refresh it
    if (swrNotes.data) {
      swrNotes.mutate()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.tab])

  useEffect(() => {
    handleBodyCssClass(open)
  }, [open])

  useOnMountEffect(() => {
    handleBodyCssClass(open)
  })

  const handleCreateNote = useCallback(async () => {
    const editor = editorRef.current

    if (!editor) {
      return
    }

    editor.replaceBlocks(editor.document, filterEditorBlocks(editor))

    const created = await inputFormAPI.create({
      assetId: null,
      dealId: null,
      investorId: null,
      advisorId: null,

      content: JSON.stringify(editor.document),
      [resourceField]: resourceId,
      tab: params.tab,
    })

    if (created) {
      // Re-fetch notes
      await swrNotes.mutate()
      // Note has been added, clear the editor
      editor.removeBlocks(editor.document)
    }
  }, [inputFormAPI, params.tab, resourceField, resourceId, swrNotes])

  const handleCreateAllNotes = useCallback(async () => {
    const tabs = routeTabs.filter((tab) => tab !== params.tab)

    setCreatingAllNotes(true)

    // Create all the notes
    for (const tab of tabs) {
      const tabNoteStorageKey = `${storageType}.${resourceId}.${tab}`
      const tabNoteContent = getEditorStateFromStorage(tabNoteStorageKey)

      if (tabNoteContent !== undefined) {
        const created = await inputFormAPI.create({
          assetId: null,
          dealId: null,
          investorId: null,
          advisorId: null,

          [resourceField]: resourceId,
          content: JSON.stringify(tabNoteContent),
          tab,
        })

        if (!created) {
          break
        }

        // Remove from storage as we now created the note
        localStorage.removeItem(tabNoteStorageKey)
      }
    }

    // Create the note for the active tab and clear editor
    await handleCreateNote()

    setCreatingAllNotes(false)
  }, [
    handleCreateNote,
    inputFormAPI,
    params.tab,
    resourceField,
    resourceId,
    routeTabs,
    storageType,
  ])

  const handleNoteDeleted = useCallback(async () => {
    // Re-fetch notes
    await swrNotes.mutate()
  }, [swrNotes])

  return (
    <>
      <IconButton onClick={() => toggleOpenNotes(!open)}>
        <MenuIcon />
      </IconButton>

      <SlotPortal slotName={SlotName.Drawer}>
        <StyledDrawer
          anchor={'right'}
          className={notesDrawerClasses.root}
          open={open}
          sx={{
            width: open ? NOTES_DRAWER_WIDTH : 0,
          }}
          variant={'persistent'}>
          <Stack
            height={'100%'}
            py={1}>
            {swrNotes.data?.items.map((note) => (
              <Note
                key={`${note.id}-${note.updatedAt}`}
                note={note}
                onDelete={handleNoteDeleted}
              />
            ))}

            {swrNotes.loading && (
              <Stack
                alignItems={'center'}
                gap={1}
                height={'100%'}
                justifyContent={'center'}
                textAlign={'center'}>
                <CircularProgress size={24} />
                <Typography variant={'caption'}>Loading notes</Typography>
              </Stack>
            )}

            {!swrNotes.loading && swrNotes.data?.items.length === 0 && (
              <Stack
                height={'100%'}
                justifyContent={'center'}
                textAlign={'center'}>
                <Typography variant={'caption'}>There are no notes for this tab</Typography>
              </Stack>
            )}

            <div style={{ flexGrow: 1 }} />

            <StyledNewNoteContainer>
              <Stack
                minHeight={200}
                mt={1}>
                <NoteEditor
                  ref={editorRef}
                  readonly={creatingAllNotes}
                  storageKey={`${storageType}.${resourceId}.${params.tab}`}
                />

                <Stack
                  direction={'row'}
                  gap={1}
                  p={2}>
                  <Button
                    disabled={creatingAllNotes}
                    onClick={handleCreateNote}
                    sx={{ flex: 1 }}
                    variant={'contained'}>
                    Add this note
                  </Button>

                  <LoadingButton
                    loading={creatingAllNotes}
                    onClick={handleCreateAllNotes}
                    variant={'outlined'}>
                    Add all notes
                  </LoadingButton>
                </Stack>
              </Stack>
            </StyledNewNoteContainer>
          </Stack>
        </StyledDrawer>
      </SlotPortal>
    </>
  )
}
